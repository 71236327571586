
import Vue, { VueConstructor } from 'vue'
import AlertInfo from '@/components/AlertInfo.vue'
import Locale from '@/components/Locale.vue'
import SingleUploadCard from '@/components/SingleUploadCard.vue'
import { EventBus } from '@/plugins/eventBus'
import SubscriptionMixin from "@/mixins/SubscriptionMixin.vue"
import AlertError from "@/components/AlertError.vue"
import SubscriptionConfirmation from "@/components/subscription/SubscriptionConfirmation.vue"
import axios, { AxiosError } from "axios"


interface FormData {
  [key: string]: string|number|null
}

interface OCRResult {
  file: string,
  error: string,
  result: string
}

interface MappingCv {
  key: string,
  value: string
}

interface Agency {
  id: number,
  name: string,
  logo: string | null,
  prefix: string | null,
  ocr: number | null
}


export default (Vue as VueConstructor<Vue & InstanceType<typeof SubscriptionMixin>>).extend({
  name: 'SubscriptionDocuments',
  components: {
    AlertInfo,
    SingleUploadCard,
    AlertError,
    SubscriptionConfirmation,
    Locale
  },
  mixins: [SubscriptionMixin],
  props: {
    kiosk: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return{
      file: null as string|null,
      files: [],
      formData: {
        cv_document: null,
      } as FormData,
      waitRequest: false,
      rgpd: false,
      selectedSpace: {} as Agency,
      visibility: "",
      step: 1
    }
  },
  computed: {
    spaces() {
      let filteredOcrSpaces: Agency[] = []
      this.agencies.forEach((space: Agency) => {
        if (space.ocr === 1) {
          filteredOcrSpaces.push(space)
        }
      })
      return filteredOcrSpaces
    },
    consent(): boolean {
      return this.rgpd && (this.selectedSpace !== null && Object.keys(this.selectedSpace).length !== 0)
    }
  },
  methods: {
    showLoader() {
      this.waitRequest = true
      this.visibility = "hidden-vs"
    },
    filterAgencies(item: Agency, queryText: string, itemText: string) {
      const words = [...queryText.toLocaleLowerCase()]
      const items = [...itemText.toLocaleLowerCase()]

      if(words.length < 2){
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      } else {
        return words.every(word => items.indexOf(word) > -1)
      }
    },
    onFileUploaded(ref: keyof FormData, data: string, ocr: OCRResult) {
      (this.formData[ref] as string) = data
      if (ocr.file.includes('error')) {
        this.displaySnackbar(ocr.error, 'error')
        this.resetData()
      } else if (this.selectedSpace !== null) {
        if (ocr.result) {
          this.subscribeWorker(ocr, data)
        } else {
          this.resetData()
          this.errorSnackbar()
        }
      }
    },
    async downloadImage(url: string): Promise<Blob> {
      return axios.get(url, { responseType: 'blob' })
        .then((response) => {
          return response.data
        })
        .catch(() => {
          return null // non-blocking process
        })
    },
    async subscribeWorker(ocr: OCRResult, filename: string) {
      let body: FormData = {
        agency_id: this.selectedSpace.id,
        url: this.$route.params.agencySubDomain + '.dispojob.pro',
        cv_document: filename
      }

      // affect returned data from OCRisation
      JSON.parse(ocr.result).forEach((dataOcr : MappingCv) => {
        Object.assign(body, dataOcr)
      })

      this.waitRequest = true

      
      // retrieve & upload extra documents if any
      if (body.cv_url && body.cv_url != "") {
        const formData = new FormData();
        formData.append("files[]", await this.downloadImage(body.cv_url as string), 'CV anonymisé.jpeg')
        const response = await axios.post("/uploads", formData)
        if (response.data.success && response.data.success[0]) {
            body.anonymized_cv_document = response.data.success[0]
        }
      }
      if (body.photo_url && body.photo_url != "") {
        const formData = new FormData();
        formData.append("files[]", await this.downloadImage(body.photo_url as string), 'Photo.jpeg')
        const response = await axios.post("/uploads", formData)
        if (response.data.success && response.data.success[0]) {
            body.photo_document = response.data.success[0]
        }
      }
      // 1st try subscription
      return axios.post('/v1/subscription/subscribe', body)
      .then(() => {
        // 1st try succeded, reset after 10s
        this.step = 2
        setTimeout(() => { this.resetData() }, 10000)
      })
      .catch((error1: AxiosError) => {
        // in case of subscription failure on first try
        // remove keys in error and retry
        const errorMappings: {[key: string]: {field: string, value: string|null}} = {
          "Invalid sex": { field: "sex", value: "M" },
          "Invalid first_name": { field: "first_name", value: "Non-détécté" },
          "Invalid last_name": { field: "last_name", value: "Non-détécté" },
          "Invalid email": { field: "email", value: null },
          "Invalid mobile_phone_number": { field: "mobile_phone_number", value: null },
          "Invalid birth_date": { field: "birth_date", value: null },
          "Invalid nationality": { field: "nationality", value: null },
          "Invalid postal_address": { field: "postal_address", value: null },
          "Invalid postal_city": { field: "postal_city", value: null },
          "Invalid postal_code": { field: "postal_code", value: null },
          "Invalid postal_country": { field: "postal_country", value: null },
          "Invalid introduction_text": { field: "introduction_text", value: null },
          "Invalid postal_complement": { field: "postal_complement", value: null },
          "Invalid birth_department": { field: "birth_department", value: null },
          "Invalid birth_city": { field: "birth_city", value: null },
          "Invalid birth_country": { field: "birth_country", value: null },
          "Invalid favorite_jobs": { field: "favorite_jobs", value: null }
        };
        // Iterate over the errorMappings and adjust the body fields
        Object.keys(errorMappings).forEach((errorKey) => {
          if (error1.response?.data[0].includes(errorKey)) {
            body[errorMappings[errorKey].field] = errorMappings[errorKey].value;
          }
        })

        // 2nd try subscription
        axios.post('/v1/subscription/subscribe', body)
        .then(() => {
          // 2nd try succeded, reset after 10s
          this.step = 2
          setTimeout(() => {  this.resetData() }, 10000)
        })
        .catch(() => {
          // in case of subscription failure on 2nd try
          // create a dummy subscription body and retry
          let finalBody: {[key: string]: number|string|null} = {
            agency_id: this.selectedSpace.id,
            url: this.$route.params.agencySubDomain + '.dispojob.pro',
            cv_document: filename,
            sex: body.sex,
            first_name: body.first_name,
            last_name: body.last_name,
            mobile_phone_number: null,
            email: filename.split('.')[0] + "@dispojob.com"
          }
          if (body.anonymized_cv_document) {
            finalBody.anonymized_cv_document = body.anonymized_cv_document
          }
          if (body.photo_document) {
            finalBody.photo_document = body.photo_document
          }
          // 3rd try subscription
          axios.post('/v1/subscription/subscribe', finalBody)
          .then(() => {
            // 3nd try succeded, reset after 10s
            this.step = 2
            setTimeout(() => {  this.resetData() }, 10000)
          })
          .catch(() => {
            // full failure, redirect on standard subscription page
            this.errorSnackbar()
            setTimeout(() => {  this.resetData() }, 3000)
          })
        })
      })
    },
    errorSnackbar(){
      let errorMessage = this.$vuetify.lang.t("$vuetify.subscription_page.error.saving")
      if (!this.kiosk) {
        EventBus.$emit('snackbar',{message: errorMessage + this.$vuetify.lang.t("$vuetify.subscription_page.error.express"), color: 'error'})
        setTimeout(() =>{this.$router.push(`/inscription/${this.$route.params.agencySubDomain}`)}, 2000)
      } else {
        EventBus.$emit('snackbar',{message: errorMessage, color: 'error'})
      }
    },
    displaySnackbar(message:string, color:string) {
      EventBus.$emit('snackbar',{message: message, color: color})
      this.resetData()
    },
    resetData() {
      if (this.spaces.length > 1) {
        this.selectedSpace = {} as Agency
      }
      this.rgpd = false
      this.formData['cv_document'] = null
      this.file = null
      this.files = []
      this.waitRequest = false
      this.visibility = ""
      this.step = 1
    }
  },
  watch: {
    agencies: function() {
      if (Object.keys(this.agencies).length === 1) {
        this.selectedSpace = (this.agencies[0] as Agency)
      }
    },
    spaces: {
      deep: true,
      handler: function (spaces) {
        if (spaces.length === 1) {
          this.selectedSpace = spaces[0]
        }
      }
    },
    selectedSpace: function() {
        let src = "dispojob_label.svg"
          if (this.selectedSpace.logo !== null && this.selectedSpace.logo !== undefined) {
            src = this.selectedSpace.logo
          }
          this.logo = ['development', 'test'].includes(process.env.NODE_ENV) ? require("@/assets/logos/" + src) : '/img/logos/' + src
        }
      }
    })
