
import Vue from "vue"
import { VueConstructor } from "vue/types/umd"
import WorkerFormInterview from "./WorkerFormInterview.vue"
import WorkerFormUpdateProfile from "@/components/worker/WorkerFormUpdateProfile.vue"
import WorkerFormDocument from "./WorkerFormDocument.vue";
import { state } from '@/plugins/state'

export default (Vue as VueConstructor<Vue & InstanceType<typeof WorkerFormInterview> & InstanceType<typeof WorkerFormUpdateProfile>>).extend({
    name: "WorkerTabsComponent",
    components: {WorkerFormInterview, WorkerFormUpdateProfile, WorkerFormDocument},
    data: () => ({
        tab: 0,
        agency:state.agency //  /!\ Remove all the agency occurrences when document goes in full prod /!\  \\
    })
})
