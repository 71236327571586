
import { EventBus } from '@/plugins/eventBus';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Vue from 'vue';

interface Body {
  rectoPath: string,
  versoPath: string | null,
  type: string,
  validityStart: string | null,
  validityEnd: string | null,
  cardNumber: string | null,
  comment: string | null,
  name: string | null
  }

export default Vue.extend({
  props: {
    swId: {
      type: Number,
      required: true,
    },
    types: {
      type: Array as () => string[],
      required: true,
    },
    rectoFileName: {
      type: String,
      required: true,
    },
    recto: {
      type: String,
      required: true,
    },
    versoFileName: {
      type: String,
      required: true,
    },
    verso: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    cardNumberTypes: [
      'identity',
      'passport',
      'residence_permit',
      'family_booklet',
      'residence_card',
      'foreign_identity',
      'social_security',
      'rib',
      'driving_licence',
      'caces',
      'habilitation',
      'fimo_fcos',
      'cdaph',
      'btp'
    ],
    versoTypes: [
      'identity',
      'passport',
      'driving_licence'
    ],
    documentTypes: [] as Array<{
      text: string,
      value: string
    }>,
    menuStart: false,
    menuEnd: false,
    selectedType: '',
    startValidityDate: '',
    endValidityDate: '',
    cardNumber: '',
    comment: '',
    name: ''
  }),
  mounted() {
    this.selectedType = this.fileType
    
    // Populates the v-select for type selection
    for (const type of this.types) {
      this.documentTypes.push({
        text : this.$vuetify.lang.t('$vuetify.document_component.'+type),
        value: type
      })
    }
  },
  watch: {
    fileType() {
      this.selectedType = this.fileType;
    }
  },
  methods: {
    saveDocument() {
      let body = {
        'rectoPath': this.recto,
        'versoPath': this.verso,
        'type': this.selectedType,
        'validityStart': this.startValidityDate ? `${this.startValidityDate} 00:00:00` : null,
        'validityEnd': this.endValidityDate ?  `${this.endValidityDate} 00:00:00` : null,
        'cardNumber': this.cardNumber === '' ? null : this.cardNumber,
        'comment': this.comment === '' ? null : this.comment,
        'name': this.name === '' ? null: this.name
      } as Body
      
      // Remove keys with no values
      let key: keyof Body
      for (key in body) {
        if (!body[key]) {
          delete body[key]
        }
      }

      axios
        .post(`/v1/workers/${this.swId}/documents`, body)
        .then((response: AxiosResponse) => {
          // emit refetch of docs
          // clear out form data and hide it
          this.$emit('filePosted', true)
          EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.document_saved')});
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error});
      })
    }
  }
});
