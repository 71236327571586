
import Vue from "vue"
import { VueConstructor } from "vue/types/umd"
import EmailRulesMixin from "@/mixins/EmailRulesMixin.vue"
import { EventBus } from "@/plugins/eventBus"
import { state } from "@/plugins/state"
import axios, { AxiosError, AxiosResponse } from "axios"
import { TiptapVuetify } from "tiptap-vuetify"
import TiptapConfigMixin from "@/mixins/TiptapConfigMixin.vue"
import AlertWarning from "@/components/AlertWarning.vue"

interface SuperWorker {
  id: number,
  first_name: string,
  last_name: string,
  cv: string,
  introduction_text: string | null
}

interface Contact {
  email: string | null,
  accept_active_proposal: boolean
}

interface Client {
  id: number,
  space_name: string,
  name: string,
  contacts: Contact[]
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof EmailRulesMixin> & InstanceType<typeof TiptapVuetify>>).extend({
  name: "SendCVDialog",
  mixins: [
    EmailRulesMixin,
    TiptapConfigMixin
  ],
  components: {
    AlertWarning,
    TiptapVuetify
  },
  props: {
    value: { // used to make v-model works
      type: Boolean,
      default: false
    },
    selectedSW: { // super_worker selection from Parangon
      type: Array,
      required: true,
    }
  },
  data: () => ({
    valid: false, // form validation state
    loading: false, // loading state on submit button
    clients: [] as Array<{ name: string, space_name: string }>,
    searchClients: "" as string, // searched text on clients autocomplete
    selectedClients: [] as Array<Client>, // selected clients on clients autocomplete
    selectedContacts: {} as {[clientId: number]: Array<string>}, // object of client.id => array of emails, passed to body (ex: {1 => ["email1@domain.com", "email2@domain.com"]})
    selectedSWWithCV: [] as Array<SuperWorker>, // super_workers with CV used to passed data to body (id, introduction_text)
    reply_to: null as string | null, // reply_to passed to body
    content: "", // HTML Content passed to body
    hiddenAcceptActiveProposal: true
  }),
  methods: {
    init() { // initialize component by retrieving needed data
      // reinitialize form inputs
      this.valid = false
      this.loading = false
      this.clients = []
      this.searchClients = ""
      this.selectedClients = []
      this.selectedContacts = {}
      this.selectedSWWithCV = []
      this.reply_to = null
      this.content = this.$vuetify.lang.t('$vuetify.default_email_content')

      // display snackbar error and hide component if no super_worker in selection
      if (this.selectedSW.length === 0) {
        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.empty_selection'), color: 'error' })
        this.inputValue = false
        return
      }
      
      // Start - retrieve reply_to email
      if (state.agency) {
        // try to retrieve email on selected space
        // NOTE currently space is returned in an array :(
        axios.get(`/v1/spaces/${state.agency}`)
        .then((spaceResponse) => {
          if (spaceResponse.data[0].default_notification_email !== null) {
            this.reply_to = spaceResponse.data[0].default_notification_email
          } else {
            // no validate email on space, so we get user's one
            axios.get("/v1/users")
              .then((userResponse: AxiosResponse) => {
                this.reply_to = userResponse.data.email
              })
              .catch((error: AxiosError) => {
                EventBus.$emit("snackbar", { message: this.$vuetify.lang.t("$vuetify.get_user_profile_error"), color: "error" })
              })
          }
        })
        .catch((error: AxiosError) => {
          EventBus.$emit("snackbar", { message: this.$vuetify.lang.t("$vuetify.get_space_profile_error"), color: "error" })
        })
      } else {
        // just get user's email
        axios.get("/v1/users")
        .then((response: AxiosResponse) => {
          this.reply_to = response.data.email
        })
        .catch((error: AxiosError) => {
          EventBus.$emit("snackbar", { message: this.$vuetify.lang.t("$vuetify.get_user_profile_error"), color: "error" })
        })
      }
      // End - retrieve reply_to email
      
      // Start - filter selected super_worker to keep only those with CV
      let recalls: Promise<AxiosResponse>[] = []

      axios.get('/v1/workers?page=1&fields=id,first_name,last_name,cv,introduction_text&deleted_at=false&cv=true&id=' + this.selectedSW.join(','))
      .then((response: AxiosResponse) => {
        // store retrieved super_workers
        this.selectedSWWithCV = this.selectedSWWithCV.concat(response.data)
        // prepare recalls if needed
        let match = /page=(\d+)[^>]*>;\s*rel="last"/m.exec(response.headers.link)
        if (match && match[1] && parseInt(match[1]) > 1) {
          for (let i = 2; i <= parseInt(match[1]); i++) {
            recalls.push(
              axios.get(`/v1/workers?page=${i}&fields=id,first_name,last_name,cv,introduction_text&deleted_at=false&cv=true&id=` + this.selectedSW.join(','))
            )
          }
        }
      })
      .then(() => {
        // execute recalls if any
        Promise.all(recalls)
        .then((results) => {
          results.forEach((response: AxiosResponse) => {
            // store retrieved super_workers
            this.selectedSWWithCV = this.selectedSWWithCV.concat(response.data)
          })
        })
      })
      // End - filter selected super_worker to keep only those with CV
    },
    submit() { // submit form
      this.loading = true
      // create body
      let body = {
        id: [] as Array<number>,
        send_cv: {
          content: this.content,
          introduction_text: {} as {[key: number]: string },
          recipients: this.selectedContacts,
          reply_to: this.reply_to
        }
      }
      // affect super_worker.id & introduction_text
      this.selectedSWWithCV.forEach((superWorker: SuperWorker) => {
        body.id.push(superWorker.id)
        // NOTE - It's important to pass empty text instead of null value
        body.send_cv.introduction_text[superWorker.id] = superWorker.introduction_text ?? ""
      })

      // submit 
      axios.post('/v1/workers/action', body)
      .then((response: AxiosResponse) => {
        EventBus.$emit("snackbar", { message: this.$vuetify.lang.t("$vuetify.success_send_cv") })
        this.inputValue = false // close dialog
      })
      .catch((error: AxiosError) => {
        EventBus.$emit("snackbar", { axiosError: error })
      })
      .finally(() => {
        this.loading = false
      })
    },
    // remove Contacts without email from array
    filterContactsWithEmail(contacts: Contact[]) {
      return contacts.filter((contact) => {
        let activeProposition = true;
        if (this.hiddenAcceptActiveProposal) {
          activeProposition = contact.accept_active_proposal
        }
        return contact.email !== null && activeProposition
        }
      )
    },
    getClientName(client: Client) {
      return `${client.name} - ${client.space_name}`
    },
    getContactEmail(contact: Contact) {
      return contact.email
    },
    getClientText(item: any) {
      return `${this.$vuetify.lang.t("$vuetify.contacts")} "${item.name} - ${item.space_name}"`
    },
    // check if at least one client has been selected
    clientsRule(clients: Array<Client>) {
      return clients.length > 0 || this.$vuetify.lang.t("$vuetify.at_least_one_client")
    },
    sanitizeString(sentence: string): string {
      sentence = sentence.replace(/[*+?^${}()|[\]\\]/g, "")
      return sentence.trim()
    },
    buildCvUrl(cvUrl: string) {
      let url = this.sanitizeString(cvUrl)
      let tmp = url.split("/")
      tmp.splice(5, 0, "cv")
      return tmp.join("/")
    },
    /**
     * Generate mailToLink
     */
    mailto(): void {
      let params: Array<string> = []
      // recipients
      params.push('bcc=' + encodeURIComponent(Object.values(this.selectedContacts).flat().join(",")))
      // subject
      params.push("subject=" + encodeURIComponent("Proposition de talent" + (this.selectedSWWithCV.length > 1 ? 's' : '')))
      // body
      let body = ''
      const parser = new DOMParser()
      let doc = parser.parseFromString(this.content.replace('</p>', "\n</p>"), 'text/html')
      body += doc.body.textContent
      // add super_worker data
      this.selectedSWWithCV.forEach((eachSW: SuperWorker) => {
        doc = parser.parseFromString((eachSW.introduction_text ?? "").replace('</p>', "\n</p>"), 'text/html')
        let text = doc.body.textContent || doc.body.innerText
        body += "\n\n" + text + (text && text.slice(-1) == "\n" ? '' : "\n") + this.buildCvUrl(eachSW.cv) + "\n"
      })
      params.push("body=" + encodeURIComponent(body))
      // return link
      window.location.href = "mailto:?" + params.join("&")
    }
  },
  watch: {
    inputValue: function (val: boolean) {
      if (val === true) { // we init component when it is shown
        this.init()
      }
    },
    searchClients(val: string) {
      let url = '/v1/clients?deleted_at=false'
      if (val && val !== "" && val !== null && val !== undefined) {
        url += '&name=' + val
      }
      axios.get(url)
      .then((response: AxiosResponse) => {
        this.clients = response.data
      })
      .catch((error: AxiosError) => {
        EventBus.$emit("snackbar", { message: this.$vuetify.lang.t("$vuetify.get_clients_error"), color: "error" })
      })
    },
    selectedClients() {
      // remove contacts from clients no longer selected
      Object.keys(this.selectedContacts).forEach((clientId) => {
        let isClientSelected = false
        this.selectedClients.forEach((client: Client) => {
          if (Number(clientId) === client.id) {
            isClientSelected = true
          }
        })
        // remove clientId key from selected contacts
        if (isClientSelected === false) {
          delete this.selectedContacts[clientId]
        }
      })

      // auto select contacts from NEW selected clients only
      this.selectedClients.forEach((client: Client) => {
        if (!this.selectedContacts[client.id] && client.contacts.length > 0) {
          this.selectedContacts[client.id] = this.filterContactsWithEmail(client.contacts).map((contact: Contact) => {
            return contact.email
          })
        }
      })
    }
  },
  computed: {
    inputValue: { // bind value prop to our v-model on v-dialog
      get(): boolean {
        return this.value
      },
      set(val: boolean) {
        this.$emit('input', val)
      }
    },
    totalSWWithCV() {
      return this.selectedSWWithCV.length
    },
    searchedAndSelectedClients() {
      return this.selectedClients.concat(this.clients)
    }
  }
})
