import en from "vuetify/src/locale/en"

export default {
  ...en,
  locale: "English",
  code: "en",
  or: "or",
  // Developement drawer menu & associated <h1> title
  welcome: "Welcome",
  home: "Home",
  multiposting: "Multiposting",
  about: "About",
  worker: "Worker",
  worker_list: "Worker list",
  order: "Order",
  localization_examples: "Localization examples",
  clients_list: "Client List",
  expand_menu: "Expand menu",
  collapse_menu: "Reduce",
  workers_list: "Workers list",
  // Production drawer menu & associated <h1> title
  delegates: "DPAE & Confirmation",
  back_to_v1: "v2",
  logout: "Logout",
  user_settings: "Account",
  change_space: "Navigation",
  all_spaces: "All spaces",
  // Dispojob API request known errors
  api_errors: {
    401: "Invalid credentials, please reconnect", // Unauthorized
    403: "You do not have right to use this resource", // Forbidden
    404: "Requested resource doesn't exist", // Unauthorized
    415: "Unsuported file format", // Unsupported Media Type
    500: "API error, please contact support", // Internal server error
    502: "Unknown error, please retry or contact support", // Bad Gateway, happened while restarting docker container
    network: "API connection error, please retry or contact support",
    "TypeError: Failed to fetch": "API connection error, please retry or contact support",
    unknown: "Unknown error, please retry or contact support",
    "User has no rights": "You do not have access to this resource",
    "Invalid status": "Status is invalid",
    "User has no multiposting credentials": "You don't have access to the multiposting application",
    "Invalid multiposting credentials": "Your login or password does not work. You can contact Talentplug support if the problem persists",
    "Order quantity exceeded": "You cannot exceed the order quantity",
    "message is missing": 'The message is empty, please fill it in',
    "Order is not open": "Order closed",
    "Order must have at least one period": "Order must have at least one period registered"
  },
  // /workers page
  id: "Id",
  space_id: "Space id",
  position_id: "Position",
  workers: 'Workers',
  profile: "Profile",
  civil_status: "Civil status",
  nationality: "Nationality",
  birth_city: "Birthplace",
  birth_country: "Birth country",
  birth_department: "Birth department",
  nir: "Social security number",
  family_situation: "Family situation",
  child_in_charge: "Child in charge",
  payment: "Payment",
  bank_wage_category: "Wage category",
  bank_payement_category: "Payment category",
  bank_domiciliation: "Domiciliation",
  bank_beneficiary: "Beneficiary",
  bank_code: "Code",
  bank_wicket_code: "Wicket code",
  bank_account_number: "Account number",
  bank_key: "Key",
  created_on: "Created on",
  last_modified_on: "Last modified on",
  dispojob_number: "Dispojob number",
  external_reference: "External reference",
  from_space: "from space",
  executive: "executive",
  short_haul_drivers: "short haul drivers",
  teacher_contract_45: "teacher contract +45 years",
  EEC_employee: "EEC employee",
  long_haul_driver: "long_haul_driver",
  executive_pension: "executive pension",
  executive_pension_65: "executive pension +65 years",
  employee_pension: "employee pension",
  employee_pension_65: "employee pension +65 years",
  payement_category: "Payement category",
  bank_transfer: "transfert",
  bank_cheque: "cheque",
  // /clients page
  name: "Name",
  // Pagination component
  pagination_title: "Paginated data",
  // /delegates page
  delegates_description: "Une DPAE & Confirmation est une délégation de personnel chez un de vos clients.\nElle vous permet d'automatiser la D.P.A.E à l'URSSAF et l'envoi du bon de mission par SMS pour chaque personnel que vous déléguez",
  model: "Job",
  customer: "Customer",
  creation_date: "Creation date",
  starting_date: "Starting date",
  ending_date: "Ending date",
  // Themes
  theme_light: "Light theme",
  theme_dark: "Dark theme",
  // 404 page
  not_found: "Page not found",
  not_found_text1: "Please report this error by clicking on the following link: ",
  not_found_text2: "Access to the servicedesk portal",
  not_found_text3: "You have received a link to create your account on the servicedesk. We will process this request as soon as possible. Please feel free to make a printout to help us resolve the incident as soon as possible.",
  // /login page
  login_page: {
    welcome_message: "Welcome to your private Dispojob space. Access to your account is confidential.",
    forgot_password: "Forgot your password?",
    login: "Login",
    log_in: "Log in",
    password: "Password",
    valid_password: "Valid password",
    min_char: "Min 6 characters",
    brochure: "Online training brochure",
    tutorial_access: "Access tutorials",
    discover_trainings: "Discover our trainings"
  },
  // /inscription page
  else:"else",
  subscription_standard_full:"Complete subscription",
  mobile_link:"Mobile app link",
  mobile_link_subtitle:"Share the link to the dispojob mobile application or the QR code",
  subscription_express_cv:"Express creation by CV deposit",
  subscription_express_sms:"Share the link to your express online registration by SMS, including a cell phone number.",
  subscription_standard_sms:"Share the link to your online registration file by text message, including a cell phone number.",
  subscription_page: {
    error: {
      unknown: "Please check the form by clicking back or contact the space to finalize your subscription.",
      "Invalid email": "Invalid Email",
      "Invalid agency, Field agency_id is required": "Invalid space, please select a space",
      "Field space_id is required": "Please select an space",
      "Invalid sex": "Invalid Civility",
      "Invalid first_name": "Invalid First name",
      "Invalid last_name": "Invalid Last name",
      "Invalid mobile_phone_number": "Invalid Mobile phone number",
      "Invalid postal_address": "Invalid Postal address",
      "Invalid postal_complement": "Invalid Additional address",
      "Invalid postal_code": "Invalid Country code",
      "Invalid postal_city": "Invalid City",
      "Invalid postal_country": "Invalid Country",
      "Invalid birth_date (awaited format 'YYYY-MM-DD')": "Invalid Birth date",
      "Invalid birth_date (wrong value)": "Invalid Birth date",
      "Invalid birth_city": "Invalid Birth place",
      "Invalid birth_country": "Invalid Birth country",
      "Invalid nir (Pattern)": "Invalid social security number",
      "Invalid nir (Key)": "Invalid social security number's verification key",
      "email or mobile_phone_number already taken": "Email or mobile phone number already taken",
      "saving": "Error while registering",
      "express" : ", you will be redirected to the classic registration",
      "Invalid favorite_jobs size": "Jobs in demand must be between 2 and 255 characters long.",
      "Invalid favorite_jobs type": "Jobs in demand cannot be numbers.",
      "Delete process fail": "The deletion has failed"
    },
    favorite_jobs: "Jobs in demand",
    non_compliant_file_error: "Error, non-compliant file",
    error_no_space: "Error, We did not find any space corresponding to the link you entered.",
    prepare_documents: "Prepare your documents",
    mandatory_documents: "Mandatory documents",
    mandatory_resume: "You must at least provide your resume",
    documents_complete_file: "Document for a complete file",
    your_resume: "Your resume",
    resume: "Resume",
    identity_document: "Identity document",
    social_security_document: "Social security card or certificate",
    residence_proof:"Proof of residence",
    banque_identity: "Banque identity",
    work_certificate: "Work certificate",
    training_certificate: "Training certificate",
    medical_checkup: "Medical check-up certificate",
    documents_quality_info: "Your documents and photos should be of good quality and well framed.",
    documents_format_info: "Uploaded files must be less than 8 MB in size and in the following formats: .jpg, .jpeg, .png, .pdf, .doc, .docx, .odt or .rtf",
    complete_file_warning: "The more complete your file is, the sooner you will have a chance to work.",
    file_usage:"By submitting this form, I agree that the information entered may be used in connection with my job application, the management of my employability and the contractual and/or commercial relationship that may result from it.",
    sign_in:"Sign in",
    marital_status_info: "The following information is collected in order to declare your employment in France. If this is not your case, click on Continue",
    invalid_nir: "This social security number is incorrect",
    invalid_nir_key: "The verification key for this social security number is invalid",
    other_id:"Other identification",
    other_documents: "Other document",
    confirmation: {
      title: "Congratulations, it's done!",
      text_1: "Congratulations, registration is complete.",
      text_2: "Your employer or recruiter will contact you to continue the recruitment process.",
      text_3: "Good luck and good recruitment.",
      video_1: "Video of the operation",
      video_2: "1 Text, 1 Job!",
      new_subscription: "New subscription"
    },
    cet_consent: "I want to open a time savings account.",
    kiosk_subscription: "Kiosk subscription",
    portrait_guide: "Place your face in the center of the camera",
    scanner_guide: "Place the document on the scanner's base",
    webcam_error: "An error has occurred. Please check the webcam access permissions or if the webcam is being used by another source."
  },
  drag_and_drop: "drag and drop",
  identity_card: "Identity card",
  identity_card_verso: "Identity card verso",
  passport: "Passport",
  caces: "CACES",
  habilitation: "Habilitation",
  license: "License",
  diploma: "Diploma",
  driver_licence: "Driver's license",
  other: "Other",
  subscription: "Subscription",
  photo: "Photo",
  birth_date:'Birth date',
  required_field: "Field is required",
  phone_or_email_required: "Phone or Email is required",
  preparation: "Preparation",
  contact_details: "Contact details",
  marital_status: "Marital status",
  documents: "Documents",
  employer: "Employer",
  resident_permit: "Resident permit",
  confirmation: "Confirmation",
  continue: "Continue",
  return: "Return",
  // Calendar
  fullcalendar: "FullCalendar",
  // /calendar page
  status: "Status",
  available: "Available",
  unavailable: "Unavailable",
  unknown:"Unknown",
  contract: "Under contract",
  blocked: "Blocked",
  time_start: "Starting time",
  time_end: "Ending time",
  event_created: "Event created",
  event_updated: "Event updated",
  event_deleted: "Event deleted",
  fields: "Fields",
  names: "Last name, first name",
  chronology: "Chronology",
  postal_city_code: "City, postal code",
  // Parameters
  language: 'Language',
  // Generic terms
  title: "Title",
  create: "Create",
  save: "Save",
  edit: "Edit",
  delete: "Delete",
  availability: "Availability",
  save_availability_success: "Availability has been added",
  update_availability_success:  "Availability has been updated",
  save_availability_fail: "Your operation has been cancelled because an availability has been detected. Please modify the period.",
  delete_availability_success: "Availability has been deleted",
  delete_availability_fail: "Your operation has been cancelled because the availability no longer exists.",
  update: "Update",
  close: "Close",
  open: "Open",
  cancel: "Cancel",
  choose_space: "Choose a space",
  choose: "Choose",
  space: "Space",
  mandatory: "Mandatory",
  optional: "Optional",
  required: "Required",
  email: "Email",
  phone: "Phone",
  valid_email: "Valid email",
  invalid_email: "Invalid email",
  at_least_one_email: "You must enter at least one email",
  at_least_one_client: "You must enter at least one client",
  first_name: "First name",
  firstname: "First name",
  last_name: "Last name",
  lastname: "Last name",
  postal_address: "Postal address",
  postal_complement: "Additional address",
  postal_code: "Country code",
  city: "City",
  country: "Country",
  civility: "Civility",
  mister: "Mister",
  misses: "Misses",
  start: "Start",
  single: "Single",
  in_couple: "In couple",
  married: "Married",
  divorced: "Divorced",
  widowed: "Widowed",
  civil_union: "Civil union",
  separated: "Separated",
  informations: "Informations",
  follow_up: "Follow up",
  // transport
  means_transport: "Means of transport",
  transportation_means: "Transportation means",
  car: "car",
  motorbike: "motorbike",
  bike_scooter: "bike, scooter",
  mass_transit: "mass transit",
  moped: "moped",
  walk: "walk",
  carpool: "carpool",
  password: "Password",
  none: "none",
  space_name: "Space name",
  user_name: "User name",
  category: "Category",
  invalid_size_array: "You can't add more than 3 jobs.",
  validate: "Validate",
  ok: 'OK',
  details: "Details",
  // mobile phone number input
  phone_number: "Phone number",
  mobile_phone_number: "Mobile phone number",
  invalid_mobile_phone_number: "Invalid mobile phone number",
  invalid_length: "The text's length is not valid",
  is_numeric: "This field must contain only integers",
  is_string: "This field must not contain numbers",
  // Chronology
  week: "Week",
  month: "Month",
  months: {0: "January", 1: "February", 2: "March", 3: "April", 4: "May", 5: "June", 6: "July", 7: "August", 8: "September", 9: "October", 10: "November", 11: "December"},
  date_from: "From",
  date_to: "To",
  morning: "Morning",
  evening: "Evening",
  night: "Night",
  // Roles - Permissions
  permission: "Permission",
  permissions_names: {
    "app-admin": "Owner",
    "space-users": "Administration",
    "jobs-delegate": "DPAE & Mission confirmation",
    "orders": "Orders",
    "multiposting": "Multiposting",
    order_template_temporary_work:"Temporary work orders",
    order_template_permanent:"Permanent contract orders",
    order_template_temporary:"Fixed-term orders",
    order_template_individual_contractor_aggrements:"Individual contractor aggrement contract",
    order_template_subcontracting:"Subcontracting orders"
  },
  permissions_descriptions: {
    "app-admin": "Has full rights on the whole application",
    "space-users": "Is an administrator of his Space, can create users, reset their passwords, etc...",
    "jobs-delegate": "Has the right to use the DPAE & Confirmation of mission functionality",
    "orders": "Has the right to use orders",
    "multiposting": "Has the right to use Multiposting application",
    order_template_temporary_work:"Has the right to create temporary work orders",
    order_template_permanent:"Has the right to create permanent contract orders",
    order_template_temporary:"Has the right to create temporary work orders",
    order_template_individual_contractor_aggrements:"Has the right to create individual contractor aggrements orders",
    order_template_subcontracting:"Has the right to create subcontracting orders"
  },
  // Admin
  admin: {
    title: "Owner",
    users: {
      title: "Users list",
    },
    "activity-logs": {
      title: "Activity logs"
    },
    statistics: {
      title: "Statistics",
      spaces: "Spaces",
      year: "Year",
      show_charts: "Show charts",
      please_select_one_space: "Please select atleast one space",
      select_all_spaces: "Select all spaces"
    },
  },
  schema: "Table",
  user_id: "User Id",
  created_at: "Creation Date",
  activity_logs_list:"Activity logs list",
  // Space administration page
  space_admin: {
    title: "Administration",
    users: {
      title: "User list",
    }
  },
  // User list
  user_list: "User list",
  permissions: "Permissions",
  spaces: "Spaces",
  groups: "Groups",
  last_login_at: "Last login",
  v3_last_login_at: "Last v3 login",
  deleted_at: 'Deleted at',
  api_access: "API access",
  deleted_user: "Deleted user",
  delete_users: "Delete users",
  deletion_successful: "Deletion successfully completed",
  deletion_partial: "Deletion partially successful",
  user_warning_permission: "Please note! It is not possible to assign permissions that you do not have.",
  delegation: "delegation",
  delegations: "delegations",
  // User creation
  user_creation: "Create user",
  user_API: "Create an API user?",
  is_user_admin: "Will the future user be an administrator?",
  user_successfully_created: "User successfully created",
  // User_update
  user_update: "Update user",
  v1_admin_rights: "v1 DISPOJOB TEAM rights ?",
  user_successfully_updated: "User successfully updated",
  // Paragon
  selection: "Selection",
  select_page: "Select the entire page",
  select_all_filtered: "Select all the filtered results",
  select_file: "Select a file",
  list: "List",
  asc: "Ascending",
  desc: "Descending",
  action: "Actions",
  filter: "Filters",
  filtered: "Filtered",
  total: 'Total',
  clear_filters: "Clear filters",
  search: "Search",
  searches: "Search by keyword",
  no_data_available: "No data available",
  empty_selection: "Empty selection",
  // Date
  from: "From",
  to: "To",
  select_only_one_date: "You have selected only one date.",
  filter_without_date: "Filter the results without dates?",
  do_you_want: "Do you want",
  end_date_time: "End date and time",
  start_date_time: "Start date and time",
  // Worker List
  worker_list_searches_info: "The search covers the following data: surname/first name, city, postal code, mobile, email, transport means, status, state, comments, jobs favorite, formation certificate, equipment",
  filter_by_first_last_name: "Filter by first or last names",
  separate_search_with_commas: "Separate your search items (first and last names) with commas for a multiple search",
  card: "Card",
  recruitment: "Positions",
  recruitment_total: "Number of positions",
  SMS_interaction: "SMS interactions",
  SMS_interaction_last_date: "Last SMS date",
  last_comment: "Last comment",
  comments: "Comments",
  comment:"Commentaire",
  comment_last_date: "Comment date",
  web: "web",
  employee: "employee",
  "pre-registered": "pre-registered",
  "supplements OK": "supplements OK",
  applicant: "applicant",
  candidate: "candidate",
  express: "express",
  state: "State",
  with:"With",
  without:"Without",
  deactivate: "Deactivate",
  favorite_jobs: "Favorite jobs",
  favorite_jobs_total: "Number of favorite jobs",
  availabilities: "Availabilities",
  missing_profile_picture: "Missing profile picture",
  profile_picture: "Profile picture",
  create_worker: "Create a worker",
  update_state: "Update state",
  remove_positions: "Dissociate",
  success_add_positions: "Dissociation performed",
  success_remove_positions: "Association made",
  add_comment: "Add comment",
  success_add_comment: "Comment added",
  positions: "Positions",
  state_modified: "State modified",
  worker_states: {
    web: "Web",
    candidate: "Candidate",
    employee: "Employee",
    applicant: "Applicant",
    pre_registered: "Pre-registered",
    supplement_ok: "Supplement OK"
  },
  add_positions: "Associate",
  availability_request: "Availability request",
  availability_request_created: "Availability request created",
  archive: "Archive",
  archive_confirm: "Are you sure you want to archive",
  success_archive: "Archived worker(s)",
  formation_certificate: "Formation certificate",
  equipment: "Equipment",
  is_sms_response: "SMS response present",
  // Worker Form
  worker_successfully_created: "Worker successfully created",
  worker_successfully_updated: "Worker successfully updated",
  // Order details
  order_information: "Order Information",
  order_successfully_updated: "Order successfully updated",
  no_modification_detected: "No modification have been detected",
  starting_date_less_than_ending_date: "The start date must be less than the end date",
  quantity_equal_or_greater_than_total: "The quantity must be equal or greater than total delegated workers",
  address: "Address",
  pending: "Pending",
  processing: "Processing",
  closed: "Closed",
  cancelled: "Cancelled",
  validated_worker: "Validated workers",
  order_period_successfully_deleted: "Period has been deleted",
  order_period_deletion_confirmation_title: "Period deletion confirmation",
  order_period_deletion_confirmation_text: "Deleting the period will delete all data associated with the period. Are you sure?",
  mission_proposal_sms: "is offering you a job. Click on this link to read the details and give your answer", // this string is preceded by a space name and followed by a link
  offer_the_job: "Offer the job",
  order_address_position_required: "To use geolocation, please change the address.",
  // OrderList
  order_list: {
    order_list: "Order list",
    follow_up: "Follow-up",
    arrived: "Arrived",
    contract_done: "Contract done",
    closed: "Closed",
    opened: "Opened"
  },
  follow_up_to_process: "To process",
  follow_up_start: "Start",
  follow_up_contract_to_do: "Contract to do",
  follow_up_all: "All",
  worker_names: "Worker", // filter
  user_names: "User", // filter
  started_at: "Start", // filter
  finished_at: "End date", // filter
  address_distance: "Distance",
  //OrderWorker
  periods: "Periods",
  on_period: "on period",
  select_all_periods: "All periods",
  orderWorker: "Order Workers",
  associated_with_position: "Associated with the position",
  not_associated_with_position: "Not associated with the position",
  and_available: "& available",
  selected: "Selected",
  workable: "Availability matching",
  matching_dispo: "Matching Availability",
  add_to_order: "Add to order",
  worker_already_in_contract: "Worker already in contract",
  already_in_contract: "is already in contract",
  change_status: "Change status",
  creation_success: "Creation successful",
  update_success: "Update successful",
  choose_status: "Choose a status",
  update_status: "Update",
  status_updated_at: "Availabilities update datetime",
  comment_note: "Note to mobile application users",
  warning_status: "Status mandatory",
  add: "Add",
  availability_status_title: "Availability management",
  availability_comment_title: "Comments management",
  availability_comment_limit: "Comment must be 500 characters or less",
  not_major: "-18 years old",
  sms_history: "sms history",
  send_sms: "Send SMS",
  count_sms: "SMS to send",
  sms: "Write your message",
  message_size_error: 'Message too long',
  reminder_date_error:"Reminder date must be greater than send date",
  gsm_count_explanation: {
    part_1:"if standard GSM alphabet => 1 count / character.",
    part_2:"if special character from standard GSM alphabet => 2 count / character.",
    part_3:"if at least 1 character other from one of the above cases is detected, we switch to unicode and count 2 for each character."
  },
  set_reminder_date:"Set up a reminder",
  send_date: "Date d'envoi",
  reminder_date: "Date de rappel (optional)",
  gsm_count_question:"How is my message size calculated ?",
  success_send_sms: "Sending scheduled",
  total_availability_requests: "Total availability request",
  total_availability_response: "Total availability response",
  last_update_disponibility: "Last update availability",
  display_more: "Show more",
  is_missing: "is missing",
  no_alerts: "no alert",
  alerts_details: "alerts details",
  at:"at",
  at_1:"at", // Differentiation for French
  on:"on",
  since: "Since",
  response: "Response",
  send: "Send",
  to_send: "Send",
  tracking: "tracking",
  order_status: {
    select: "selected",
    in_progress: "in progress",
    waiting: "pending",
    accepted: "accepted",
    refused: "refused",
    cancelled: "cancelled"
  },
  order_worker_status: {
    unknown: "unknown",
    available: "available",
    unavailable: "unavailable",
    blocked: "blocked",
    contract: "contract",
    awaiting_reply: "awaiting reply",
    pending: "pending"
  },
  order_template_status: {
    select: "Selected",
    in_progress: "In progress",
    waiting: "Pending",
    accepted: "Accepted",
    refused: "Refused",
    cancelled: "Cancelled",
    abandon: "Abandon"
  },
  info: "Info",
  // Order Form
  create_order: 'Create an order',
  client: 'Client',
  client_id: 'Client',
  position: 'Position',
  step: 'Step',
  quantity: 'Quantity',
  choose_client: "Choose a client",
  choose_position: "Choose a position",
  order_successfully_created: "User successfully created",
  force_available_workers: "Matching availability",
  force_available_workers_details: "If checked, the proposed staff will all have the available status during the order period",
  force_all_periods: "All periods required",
  force_all_periods_details: "By checking this option, workers are obliged to take all or none of the periods",
  positive_number_required: "Positive number required",
  order_priority:"Priority",
  matching: "matching",
  free_text: "Free text",
  period: "Period",
  period_chronology_error: "You have a chronological error in periods",
  contract_type: "Contract type",
  temporary_work: 'Temporary',
  permanent: 'Permanent',
  temporary: 'Fixed-term',
  training: 'Training',
  individual_contractor_aggrements: 'Individual contractor aggrements',
  subcontracting: 'Subcontracting',
  choose_type: "Choose type",
  blocked_period_choice: "Periods choice locked",
  // Activity log
  activity_logs: {
    title: "Title",
    description: "Description",
    type: "Type",
    schema: "Category",
    user_id: "User Id",
    created_at: "Creation Date",
    create_success: "Activity has been added",
    delete_success: "Activity has been deleted",
    new_subscription: "New candidate subscription",
    "Standard subscription": "A candidate has just registered to your agency via standard subscription. You can now access his folder.",
    "Kiosk subscription": "A candidate has just registered to your agency via kiosk subscription. You can now access his folder.",
    "Express subscription": "A candidate has just registered to your agency via express subscription. You can now access his folder.",
    user_login: "User login",
    user_login_description: "{0} has logged in to the application",
  },
  // ACTION WORKERLIST
  cv: "CV",
  // SPACE SELECTION
  url_deactivate: "Subscription desactivated for this space",
  url_express_deactivate: "Subscription Express desactivated for this space",
  sms_deactivate: "SMS sending disabled for this space",
  mobile_app_deactivate:"Mobile application disabled for this space",
  agency_code: "agency code",
  // SMS
  sms_sent: "SMS sent",
  phone_required:"Phone required",
  // APP
  direct_download:"Direct download",
  // Multiposting
  multiposting_not_activated: "The Multiposting application is not yet activated on your account.",
  talk_to_advisor: "Talk to an advisor",
  create_offer: "Create offer",
  create_offer_from_position: "Create offer from a position",
  delete_offer_confirm: "Are you sure you want to delete ?",
  offer_deleted: "Offer deleted",
  view: "View",
  republish: "Republish",
  republishing: "Republishing",
  unpublish: "Unpublish",
  offer_title: "Offer title",
  published_at: "Published at",
  jobboards: "Job boards",
  offer_status: {
    Normal: "Published",
    Archivé: "Unpublished",
    Brouillon: "Draft"
  },
  offer_republish_success: "Republishing saved",
  offer_unpublish_success: "Unpublishing saved",
  auto_republish_on: "Automatic republishing enabled",
  auto_republish_off: "Automatic republishing disabled",
  offer_delete_success: "Offer deleted",
  offer_updated_success: "Offer saved",
  offer_no_data: "You have no offer",
  republication_yes: "yes, the",
  republication_no: "no",
  client_position: "Client / Position",
  total_applicants: "Nb of applicants",
  offer_applicants: "Offer's applicants",
  deleted_applicant: "Worker deleted",
  // CV
  contacts: "Contacts",
  info_cv: 'You can add a non-present email, by writing it and pressing the "enter"',
  send_cv: "Send CV",
  response_to:"Reply to",
  email_content:"Email content",
  default_email_content:"<p>Hello,</p><p>In order to anticipate your next recruitment needs, you will find below the talents that match your criteria.</p><p>Looking forward to discussing them in person or reading your response.</p>",
  introduction_text: "Introduction text",
  cv_selection_empty: "No resume detected to enable you to use this feature.\nTo correct this, you can add a resume to the relevant worker folder(s)",
  success_send_cv: "Sending completed",
  get_user_profile_error: "Error while retrieving user profile",
  get_space_profile_error: "Error while retrieving space profile",
  get_clients_error: "Error while retrieving clients, please try again",
  send_from_mailbox: "Send from my mailbox",
  // ENTRETIEN
  entretien: {
    title: 'Interview',
    questionnaire: {
      title: "Questionnaire",
    },
    knowledge : {
      title: "Knowledge",
      note: "knowledge, diplomas, training certificates, studies, training courses, internships."
    },
    know_how : {
      title: "Know-how",
      note: "profession, experience, skills, hardware/software used."
    },
    life_skills : {
      title: "Life skills",
      note: "tastes, passions, hobbies, studies, collective/individual activities."
    },
    how_to_become : {
      title: "How to become",
      note: "contract types, durations, salaries, career plans, mobility."
    },
    introduction_text : {
      title: "Introduction text",
      note: "The presentation of this staff member will be entered automatically from the action: Send CV."
    },
    resume_keywords : {
      title: "Keywords",
      note: "This field shows you the keywords extracted from the CV."
    }
  },
  equipments: {
    title: "Equipment",
    data: {
      name: "Name",
      comment: "Comment",
      size: "Size",
    },
    action: {
      add: "Add an equipment",
      success_deleted: "Successful equipment removal",
      success_add: "Successful equipment additions",
      success_update: "Successful equipment modifications"
    }
  },
  position_search: "Jobs in demand",
  // PREFERENCES
  start_preferences: {
    title: "Start",
    data: {
      one_hour: '1 hour',
      one_day: '1 day',
      one_week: '1 week',
      one_month: '1 month',
      three_month: '3 month'
    }
  },
  environment_preferences: {
    title: "Environment",
    data: {
      outdoor: 'Outdoor',
      indoor: 'Indoor',
      cold: 'Cold',
      dust: 'Dust',
      noise: 'Noise',
      high: 'High',
      heavy_loads: 'Heavy loads'
    }
  },
  availability_preferences: {
    title: "Availability",
    data: {
      full_time: 'Full time',
      part_time: 'Part time',
      day: 'Day',
      morning: 'Morning',
      afternoon: 'Afternoon',
      night: 'Night',
      weekend: 'Weekend',
      '2_8_shift': '2_8_shift',
      '3_8_shift': '3_8_shift',
      '4_8_shift': '4_8_shift',
      '5_8_shift': '5_8_shift'
    }
  },
  can_note_here: 'You can note here',
  force_delete_text: 'Worker have already been delegated for the period, do you still want to delete it ?',
  worker_already_delegated: "Please cancel worker delegation on these periods before changing the dates and times, because they already have a contract on the periods concerned.",
  // Notifications
  notifications: "Notifications",
  show_unread: "Show only unread",
  mark_read: "Mark as read",
  mark_unread: "Mark as unread",
  mark_all_as_read: "Mark all as read",
  notification_from_the_last_30_days: "That's all your notifications from the last 30 days.",
  unread_notification_from_the_last_30_days: "That's all your unread notifications from the last 30 days.",
  today: "Today",
  yesterday: "Yesterday",
  older: "Older",
  go_to_order: "Go to order",
  go_to_profil: "Go to profil",
  hidden_notification_types: {
    1: "Hide job acceptance notifications",
    2: "Hide job refusal notifications",
    3: "Hide subscription notifications",
    4: "Hide received SMS notifications",
    5: "Hide AVAILABLE notifications",
    6: "Hide UNAVAILABLE notifications",
  },
  hidden_accept_active_proposal: "only contacts who accept the active proposal",
  closing_title: "Order closing",
  closing_subtitle: "Select a state to close order",
  confirm_closing_title: "Confirmation of closure of order",
  closing_confirmation: "Are you sure you want to close this order ?",
  to_close: "Close",
  closing_success: "Order have been successfully closed",
  closing_error: "Error when closing order",
  honored: "Honored",
  partially_honored: "Partially honored",
  not_honored: "Not honored",
  choose_periods: "Choose periods",
  all_arrived: "all arrived",
  closing_reasons: "Closing reasons",
  report: "Report",
  worker_contract_done: "Worker's contracts done",
  all_contract_done: "All worker's contracts done",
  closing_impossibility: "The order cannot currently be closed",
  report_data: {
    temporary_company: "Temporary employment agency",
    user_company: "User company",
    sender: "Sender",
    first_period_date: "Time of first appointment",
    ratio_order_job: "Order conversion rate / starting position",
    ratio_contact_job: "Contact conversion rate / starting position",
    first_job_accept_date: "Acceptance of 1st mission position",
    completion_date: "Acceptance of all vacancies",
    start_date: "Start date and time",
    worker_contacted: "Contact person(s)"
  },
  summary: "Summary",
  number_of_people_required: "Number of people required",
  begin_date: "Beginning date",
  end_date: "Ending date",
  cycle: "cycle",
  weekly_duration: "Weekly duration",
  missions: "missions",
  further_information: "Further information",
  informations_convention_collective: "Informations Convention Collective",
  manager: "Manager",
  anonymous_customer: "Anonymous customer",
  sms_validation: "SMS validation",
  display_city_in_sms:"Display city in SMS",
  sms_notification: "SMS notification",
  mail_notification: "Mail notification",
  reason_for_appeal: "Reason for appeal",
  receipt: "Receipt",
  source: "Source",
  tariff_coefficient: "Tariff coefficient",
  business_manager: "Business manager",
  // Documents
  recto: "Recto",
  verso: "Verso",
  document: "Document",
  validity_date_start: "Validity date start",
  validity_date_end: "Validity date end",
  card_number: "Card Number",
  document_type: "Document's type",
  document_saved: "Document saved",
  document_deleted: "Document deleted",
  delete_document_confirmation: "Are you sur you want to delete this document ?",
  document_component: {
    identity_documents: "Identity documents",
    carte_vital_or_certificate :"Carte vitale or certificate",
    visit_medical_aptitude:"Visit/medical aptitude",
    training_certificates: "Training certificates",
    disability_situation: "Disability status",
    btp_card: "Carte BTP",
    vaccination: "Vaccination",
    other_documents: "Other documents",
    identity: "Identity",
    cv: "CV",
    photo: "Photo",
    profile: "Photo",
    passport: "Passport",
    residence_permit: "Residence permit",
    family_booklet: "Family booklet",
    residence_card: "Residence card",
    foreign_identity: "Foreign identity",
    active_proposal: "Anonymized CV",
    social_security: "Social Security",
    rib: "RIB",
    proof_of_address: "Proof of address",
    medical_examination: "Medical examination", 
    work_certificate: "Work certificate", 
    driving_licence: "Driving licence", 
    fimo_fcos: "FIMO FCOS",
    caces: "CACES",
    habilitation: "Authorization",
    diploma: "Diploma",
    other_certificate: "Other certificate",
    cdaph: "CDAPH",
    btp: "BTP card",
    vaccination_booklet: "Vaccination booklet",
    card_number: "Card number",
    other: "Autre",
    contract: "Contracts",
    internal_document: "Internal documents",
    payslip: "Payslips",
    timesheet: "Time sheets",
    employer_attestation: "Attestation",


    
  },
  upload_your_document: "Upload your document"

}
