
import DateFormatMixin from "@/mixins/DateFormatMixin.vue";
import { mutations, state } from "@/plugins/state";
import axios, { AxiosResponse } from "axios";
import { format } from "date-fns";
import Vue, { VueConstructor } from "vue"
import NotificationItem from "./NotificationItem.vue";

type NotificationData = {
  [key: string]: number|string
}

interface Notification {
  id: number,
  type: number,
  read: boolean,
  created_at: string,
  title: string,
  message: string,
  data: NotificationData,
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof DateFormatMixin>>).extend({
  name: "Notification",
  mixins: [DateFormatMixin],
  components: {
    NotificationItem
  },
  data () {
    return {
      notifications: [] as Notification[],
      onlyUnreadFilter: state.showUnreadNotification,
      displayNotifications: false,
      displayOptions: false,
      timer: 0,
      hiddenNotifications: state.hiddenNotificationTypes as string[],
      markAllAsReadLoading: false,
    }
  },
  computed: {
    /**
     * Calculate the best virtual scroller height
     */
    getScrollerHeight: function() {
      return (this.$vuetify.breakpoint.height - 80) < 500 ? (this.$vuetify.breakpoint.height - 80) + 'px' :  '500px'
    },
    /**
     * Calculate total of unread notifications, displayed or not
     */
    unread: function(): number {
      return this.notifications.filter(notification => {
        if (this.hiddenNotifications.includes(String(notification.type))) {
          return false
        }
        return !notification['read']
      }).length
    },
    displayedNotifications: function(): Notification[] {
      // Apply filters
      let filteredNotifications =  this.notifications.filter((notification: Notification) => {
        if (this.hiddenNotifications.includes(String(notification.type))) {
          return false
        }
        if (this.onlyUnreadFilter) {
          return notification.read === false
        }
        return true
      })
      // add fake item for "end of notifications" message display
      filteredNotifications.push({
          "id": -1,
          "type": -1,
          "read": false,
          "created_at": "",
          "title": "",
          "message": "fake item for end of notifications display",
          "data": {}
      })
      // return
      return filteredNotifications
    }
  },
  mounted() {
    this.getNotifications()
    this.timer = setInterval(() => {
      this.getNotifications(true)
    }, 5000)
  },
  watch: {
    onlyUnreadFilter (val) {
      mutations.setShowUnreadNotification(val)
    },
    hiddenNotifications: {
      handler(){
        mutations.setHiddenNotificationTypes(this.hiddenNotifications)
      },
     deep: true
  }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    readNotification(notification: Notification) {
      // prevent failure on fake last item
      if (notification.id === -1) {
        return
      }

      axios.put(`/v1/notifications/${notification.id}`,
        {
          read: !notification.read
        }
      )
      .then(() => { // HTTP status between 200 & 299
        notification.read = !notification.read // reflect success on front data
      })
    },
    markAllAsRead() {
      // prevent multiple click because function call isn't on btn
      if (this.markAllAsReadLoading !== false) {
        return
      }
      this.markAllAsReadLoading = true
      // build body
      let body = {} as {[key: number]: {read: boolean}}
      for (const notification of this.displayedNotifications) {
        if (notification.id !== -1) {
          body[notification.id] = {
            read: !notification.read
          }
        }
      }

      axios.put(`/v1/notifications`, body)
      .then((response: AxiosResponse) => { // HTTP status between 200 & 299
        let errorIds = Object.keys(response.data)

        for (let notification of this.displayedNotifications) {
          if (notification.id !== -1 && errorIds.includes(String(notification.id)) === false) { // exclude fake item && errors from visual update
            notification.read = body[notification.id].read // reflect success on front data
          }
        }
      })
      .finally(() => {
        this.markAllAsReadLoading = false
      })
    },
    getNotifications(refresh = false) {
      let url = '/v1/notifications'
      if (refresh) {
        // We set the filter 5 seconds from now
        const date = new Date(Date.now() - 5000);
        const dateTimeString = format(date, 'yyyy-MM-dd HH:mm:ss')
        url += `?from=${dateTimeString}`
      }
      axios.get(url)
      .then(response => { // HTTP status between 200 & 299
        if (response.data.length > 0) {
          // retrieve only new notifications not already in this.notifications
          this.notifications = [...response.data, ...this.notifications].filter((value, index, self) => {
            return index === self.findIndex((t) => ( t.id === value.id ))
          })
        }
      })
    }
  }
})
