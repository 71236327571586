
import FilesMixin from "@/mixins/FilesMixin.vue";
import { EventBus } from "@/plugins/eventBus";
import axios, { AxiosError } from "axios";
import Vue from "vue"
import { VueConstructor } from "vue/types/umd";

  interface Document {
    id: number,
    candidateId: number,
    recto_path: string,
    verso_path: string,
    type: string,
    name: string,
    created_at: string,
    updated_at: string,
    deleted_at: string,
    validity_start: string,
    validity_end: string,
    ocrRecto_id: number,
    ocrVerso_id: number,
    comment: string,
    cardNumber: string,
    verified: boolean,
  }

  interface DocumentList{
    [key: string]: Document[]
  }

  export default (Vue as VueConstructor<Vue & InstanceType<typeof FilesMixin>>).extend({
    name: 'DocumentCategory',
    mixins: [
      FilesMixin,
    ],
    props: {
      swId: {
        type: Number,
        required: true
      },
      categories: {
        type: Array as () => string[],
        required: true,
      },
      types: {
        type: Object as () => {[key: string]: string[]},
        required: true,
      },
      documents: {
        type: Object as () => DocumentList,
        required: true,
      }
    },
    data:() => ({
      isModalOpen: false,
      isDeleteConfirmOpen: false,
      rectoFile: null as null | File,
      versoFile: null as null | File,
      rectoOGName: '',
      versoOGName: '',
      rectoButtonColor: 'default',
      toEmitRecto: {} as {
        originalName: string,
        type: string,
        returnedName: string
      },
      toEmitVerso: {} as {
        originalName: string,
        type: string,
        returnedName: string
      },
      versoButtonColor: 'default',
      selectedType: '',
      selectedCategory: '',
      requiresVerso: false,
      versoCategories: [
        'identity_documents',
        'driving_licence',
      ],
      categoriesMultipleTypes: [
        'identity_documents',
        'cv',
        'training_certificates'
      ],
      categoryTypes: [] as Array<{
        text: string,
        value: string
      }>,
      documentId: 0
    }),
    watch: {
      isModalOpen() {
        // When the upload modal gets close, we reset all upload related data
        if (!this.isModalOpen) {
          this.rectoFile = null
          this.versoFile = null
          this.rectoOGName = ''
          this.versoOGName = ''
          this.rectoButtonColor = 'default'
          this.versoButtonColor = 'default'
          this.selectedType = ''
          this.categoryTypes = []
        }
      },
    },
    methods: {
      handleSelectFile(side: string) {
        const fileInputs = this.$refs[side] as Vue & {
          $refs: {
            input: HTMLElement
          }
        };
        fileInputs.$refs.input.click();
      },
      openModal(category: string) {
        this.selectedCategory = category
        let availableTypes = Object.keys(this.types).filter(key => this.types[key].includes(this.selectedCategory))
        for (const type of availableTypes) {
          this.categoryTypes.push({
            text : this.$vuetify.lang.t('$vuetify.document_component.'+type),
            value: type
          })
        }

        // If the category contains only 1 type, it is set as the selected type since no select will be shown in the template
        if (this.categoryTypes.length === 1){
          this.selectedType = this.categoryTypes[0].value
        }
        this.requiresVerso = this.versoCategories.includes(category)
        this.isModalOpen = true
      },
      openConfirmDeleteModal(workerId: number, documentId: number) {
        this.isDeleteConfirmOpen = true
        this.documentId = documentId
      },
      handleFileDrop(event: DragEvent, category: string, side = 'recto') {
        event.preventDefault()
        const files = event.dataTransfer?.files
        if (files && files[0]) {
          this.rectoFile = files[0]
          this.openModal(category)
          this.uploadFile(files[0], side)
        }
      },
      validateUpload() {
        if (this.rectoFile && !this.requiresVerso) {
          this.toEmitRecto.type = this.selectedType
          this.$emit("uploadFile", this.toEmitRecto, null)
        }
        if (this.rectoFile && (this.requiresVerso && this.versoFile)) {
          this.toEmitRecto.type = this.selectedType
          this.toEmitVerso.type = this.selectedType
          this.$emit("uploadFile", this.toEmitRecto, this.toEmitVerso)
        }
        this.isModalOpen = false
      },
      uploadFile(file: File, side: string) {
        if (file && file.size <= 8388608) {
          let formData = new FormData()
          formData.append("files[]", file)

          axios
            .post("/uploads", formData)
            .then((response) => {
              // Build the objects to send to the DocumentForm through WorkerFormDocument
              if (side === 'recto') {
                this.toEmitRecto = {
                  originalName: file.name,
                  type: this.selectedType,
                  returnedName: response.data.success[0]
                }
                this.rectoButtonColor = 'primary'
              } else {
                this.toEmitVerso = {
                  originalName: file.name,
                  type: this.selectedType,
                  returnedName: response.data.success[0]
                }
                this.versoButtonColor = 'primary'
              }
            })
            .catch((error: AxiosError) => {
              EventBus.$emit('snackbar', { axiosError: error })
          })
        } else {
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.subscription_page.non_compliant_file_error'), color: 'error'})
        }
      },
      deleteDocument(workerId: number, documentId: number) {
        axios
        .delete(`/v1/workers/${workerId}/documents/${documentId}`)
        .then(() => {
            // success snackbar
            EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.document_deleted') })
            this.$emit("fileDeleted", true)
            this.isDeleteConfirmOpen = false
            this.documentId = 0
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error})
        })
      },
      downloadDocument(rectoUrl: string, versoUrl = null as null|string) {
        this.launchFileDownload(rectoUrl)
        if (versoUrl){
          this.launchFileDownload(versoUrl)
        }
      }
    }
  })
